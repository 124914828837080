import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import history from "../store/history";

import auth from "./auth.reducer";
import app from "./app.reducer";
import user from "./user.reducer";
import company from "./company.reducer";
import contact from "./contact.reducer";
import brand from "./brand.reducer";
import search from "./search.reducer";
import main_category from "./registry/registry.main_category.reducer";
import sector from "./registry/registry.sector.reducer";
import functionReg from "./registry/registry.function.reducer";
import income_range from "./registry/registry.income_range.reducer";
import employer_range from "./registry/registry.employer_range.reducer";
import international_invest from "./registry/registry.international_invest.reducer";
import other_categories from "./registry/registry.other_categories.reducer";
import funding_type from "./registry/registry.funding_type.reducer";
import medium_planning from "./registry/registry.medium_planning.reducer";
import nielsen from "./nielsen.reducer";
import pitch_category from "./registry/registry.category.reducer";
import pitch from "./pitch.reducer";
import home from "./home.reducer";
import tag from "./tag.reducer";
import comment from "./comment.reducer";
import flatV2 from "./flatV2.reducer";
import ecommerce from "./ecommerce.reducer";

const rootReducer = combineReducers({
  router: connectRouter(history),
  company,
  contact,
  brand,
  app,
  auth,
  user,
  search,
  main_category,
  sector,
  functionReg,
  income_range,
  employer_range,
  international_invest,
  other_categories,
  medium_planning,
  nielsen,
  pitch_category,
  pitch,
  home,
  tag,
  comment,
  funding_type,
  flatV2,
  ecommerce,
});

export default rootReducer;
