import { getFromLocalStorage } from "./helpers/localstorage";

function feature(name, prod, test, dev) {
  return {
    get [name]() {
      if (window.config.environment === "dev") {
        if (typeof dev === "boolean") return dev;
        if (typeof test === "boolean") return test;
        return prod;
      }
      if (window.config.environment === "test") {
        if (typeof test === "boolean") return test;
        return prod;
      }
      return prod;
    },
  };
}

const def = {
  ...feature("companyInvestments", true),
  ...feature("collectiveAgreements", false, false),
  ...feature("comments", true),
  ...feature("entityNews", false),
  ...feature("jobOffers", false),
  ...feature("atecoCode", true),
  ...feature("companyFinancials", true),
  ...feature("investmentRounds", true),
};

const features = {
  default: def,
  france: {
    ...def,
    ...feature("collectiveAgreements", true),
    ...feature("entityNews", true),
    ...feature("jobOffers", true),
  },
  italy: {
    ...def,
    ...feature("entityNews", true),
    ...feature("jobOffers", true),
  },
  spain: {
    ...def,
    ...feature("atecoCode", false),
  },
  mexico: {
    ...def,
    ...feature("atecoCode", false),
  },
  portugal: {
    ...def,
  },
  uk: {
    ...def,
    ...feature("atecoCode", false),
    ...feature("companyFinancials", false),
    ...feature("investmentRounds", false),
  },
};

export default features;

export function getFeatures() {
  const tenant = getFromLocalStorage("lefacWebTenant")?.data || "default";
  return features[tenant];
}

window.getFeatures = getFeatures;
