import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { get } from "./libs/helpers/ioc";

import routes from "./routes";

import history from "./store/history";
import Router from "./libs/router";

import Loading from "./components/Loading/Loading";
import ErrorBoundary from "./libs/ErrorBoundary";
import { setSidebarCollapsed } from "./actions/app.actions";

import "./App.scss";
import "./App.less";
import theme from "./design-library/theme";
import { getPlanConfigurations, isBillingInfoComplete } from "./libs/ecommerce";
import {
  getFromLocalStorage,
  setOnLocalStorage,
} from "./libs/helpers/localstorage";
import { getActivePlansAction } from "./actions/ecommerce.actions";

function AppRouter({ authorized, user, permissions }) {
  const activePlans = useSelector((state) => state.ecommerce.activePlans);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const { searchParams } = url;
      const selectedPlan = searchParams.get("selectedPlan");
      const selectedTenant = searchParams.get("selectedTenant");
      if (selectedTenant) {
        setOnLocalStorage(selectedTenant, "lefacWebTenant");
      }
      if (selectedPlan) {
        setOnLocalStorage(selectedPlan, "blinko_requested_plan");
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    try {
      const tenant = getFromLocalStorage("lefacWebTenant")?.data;
      if (authorized && user?.tags?.includes("registration_flow")) {
        // User is authenticated and it's a regFlow user
        if (history.location.pathname !== "/completeBillingInfo") {
          // they are not currently on completeBillingInfo page
          const billingInfoComplete = isBillingInfoComplete(
            user.billingInfo || {}
          );
          if (!billingInfoComplete.complete) {
            // Their billingInfo is not complete, so force them to complete it
            history.push("/completeBillingInfo");
          } else if (history.location.pathname !== "/past-due") {
            if (user?.overdue) {
              history.push("/past-due");
            } else {
              // Their billingInfo is complete
              const requestedPlan = getFromLocalStorage(
                "blinko_requested_plan"
              );
              if (
                requestedPlan &&
                getPlanConfigurations(tenant)?.[requestedPlan.data] &&
                !getPlanConfigurations(tenant)?.[requestedPlan.data].archived
              ) {
                // Have they requested a specific plan?
                //    If so, do they have it? If they do just take them home
                //           Otherwise get them to the flow to get it
                const plan = getPlanConfigurations(tenant)?.[
                  requestedPlan.data
                ];
                if (
                  !activePlans.data &&
                  !activePlans.loading &&
                  !activePlans.error
                ) {
                  dispatch(getActivePlansAction());
                } else if (activePlans.data) {
                  const active = activePlans.data.find(
                    (p) => p.priceId === plan.priceId
                  );
                  if (active) {
                    setOnLocalStorage("", "blinko_requested_plan");
                  } else if (
                    history.location.pathname !== "/completeBillingInfo" &&
                    history.location.pathname !== "/confirmPlan" &&
                    history.location.pathname !== "/paymentFailed" &&
                    history.location.pathname !== "/paymentSuccessful"
                  ) {
                    history.push("/confirmPlan");
                  }
                }
              } else if (
                !user?.tags?.includes("regFlow_confirmed_trial") &&
                history.location.pathname !== "/confirmPlan"
              ) {
                history.push("/confirmPlan");
              }
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [authorized, user, activePlans]);
  return (
    <Router routes={routes} authorized={authorized} permissions={permissions} />
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    // this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  componentDidMount() {
    if (window.screen.width < 1024) {
      setSidebarCollapsed(true);
    }
  }

  componentDidCatch(error, info) {
    this.setState({ caughtError: true });
    console.log("[ERROR Catched]", error, info);
  }

  render() {
    const { authorized, user, loading } = this.props;
    const permissions = get("scopes");
    return (
      <>
        <Helmet>
          <title>Blinko</title>
        </Helmet>
        {loading && !user && <Loading />}
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <ErrorBoundary>
              <AppRouter
                authorized={authorized}
                user={user}
                permissions={permissions}
              />
            </ErrorBoundary>
          </ConnectedRouter>
        </ThemeProvider>
      </>
    );
  }

  // _onAction(e) {
  //   console.log('user did something', e);
  // }

  _onActive(e) {
    console.log("user is active", e);
    console.log("time remaining", this.idleTimer.getRemainingTime());
    // userInfo();
    // getCuboidListAction();
  }

  _onIdle(e) {
    //   console.log('user is idle', e);
    console.log("last active", this.idleTimer.getLastActiveTime());
  }
}

const mapStateToProps = ({ auth }) => ({
  /* Sets the authorized flag on the routes object if an access_token is available */
  // authorized: false
  authorized: !!auth.user && !!auth.token,
  loading: auth.loading,
  user: auth.user,
});

export default connect(mapStateToProps, { setSidebarCollapsed })(App);
